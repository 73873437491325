/* eslint-disable no-restricted-syntax */
import store from '@/store';
import {
    ref,
    reactive,
    // watch
} from '@vue/composition-api';
import i18n from '@/libs/i18n/index';
import useHandleError from '@/utils/useHandleError';

export default function useRarities() {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);
    const locale = ref(i18nReactive.locale);

    const fetchRarities = () => {
        return store
            .dispatch('app-rarities/fetchRarities', { locale: locale.value })
            .catch(showErrorMessage);
    };

    return {
        // Methods
        fetchRarities,
    };
}
