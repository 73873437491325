import usePositionedToast from '@/utils/usePositionedToast';

export default function useHandleError() {
    const { showNotification } = usePositionedToast();

    const showErrorMessage = (error) => {
        let { message } = error;

        if (error.response) {
            message = error.response.data.message;
        }

        showNotification({
            title: message,
            variant: 'danger',
        });

        return Promise.reject(error);
    };

    return {
        // Methods
        showErrorMessage,
    };
}
