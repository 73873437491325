<template>
    <router-view />
</template>

<script>
import { reactive, watch, onMounted } from '@vue/composition-api';

import i18n from '@/libs/i18n/index';

import useWeapons from '@/views/weapons/useWeapons';
import useRarities from '@/views/rarities/useRarities';

export default {
    setup() {
        const { fetchWeaponsGroups } = useWeapons();
        const { fetchRarities } = useRarities();

        const i18nReactive = reactive(i18n);

        const fetchData = async () => {
            await fetchWeaponsGroups();
            await fetchRarities();
        };

        onMounted(() => fetchData());

        watch(
            () => i18nReactive.locale,
            () => fetchData()
        );
    },
};
</script>

<style lang="scss" scoped></style>
