// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function usePositionedToast() {
    // Use toast
    const toast = useToast();

    const getIconName = (variant) => {
        if (variant === 'success') return 'CheckIcon';
        if (variant === 'danger') return 'XIcon';
        if (variant === 'warning') return 'AlertTriangleIcon';
        return 'InfoIcon';
    };

    const showNotification = ({
        title = '',
        variant = 'success',
        position = 'bottom-right',
    }) => {
        const icon = getIconName(variant);

        toast(
            {
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            },
            {
                position,
            }
        );
    };

    return {
        showNotification,
    };
}
